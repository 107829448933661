import plugin_vue3_A0OWXRrUgq from "/builds/teamtilt/web/tilt-web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/teamtilt/web/tilt-web/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/builds/teamtilt/web/tilt-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/teamtilt/web/tilt-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/builds/teamtilt/web/tilt-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/builds/teamtilt/web/tilt-web/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/teamtilt/web/tilt-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import amplify_cCHdTinyKG from "/builds/teamtilt/web/tilt-web/plugins/amplify.ts";
import livestream_client_FgIlKd1BNu from "/builds/teamtilt/web/tilt-web/plugins/livestream.client.ts";
import lottie_player_client_G22svxIhbR from "/builds/teamtilt/web/tilt-web/plugins/lottie-player.client.ts";
import particles_client_ocgEtgMfVV from "/builds/teamtilt/web/tilt-web/plugins/particles.client.ts";
import remote_config_client_JFFYVBzW8D from "/builds/teamtilt/web/tilt-web/plugins/remote-config.client.ts";
import sentry_client_shVUlIjFLk from "/builds/teamtilt/web/tilt-web/plugins/sentry.client.ts";
import vue_gtag_client_CYt6toK02h from "/builds/teamtilt/web/tilt-web/plugins/vue-gtag.client.ts";
import vue_gtm_client_stBjLl0OeM from "/builds/teamtilt/web/tilt-web/plugins/vue-gtm.client.ts";
import vue_toastification_gZJw6dQQf2 from "/builds/teamtilt/web/tilt-web/plugins/vue-toastification.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  amplify_cCHdTinyKG,
  livestream_client_FgIlKd1BNu,
  lottie_player_client_G22svxIhbR,
  particles_client_ocgEtgMfVV,
  remote_config_client_JFFYVBzW8D,
  sentry_client_shVUlIjFLk,
  vue_gtag_client_CYt6toK02h,
  vue_gtm_client_stBjLl0OeM,
  vue_toastification_gZJw6dQQf2
]