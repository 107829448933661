<script setup lang="ts">
const answeredConsent = ref('');

const checkAnswer = () => {
  answeredConsent.value = localStorage.getItem('consent');
};

if (process.client) {
  checkAnswer();
}
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <CookieConsent v-if="!answeredConsent" @answer="checkAnswer"/>
</template>

<style>
#__nuxt {
  @apply h-full;
}
</style>
