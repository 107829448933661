import { initializeApp } from 'firebase/app';
import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config';

export type RemoteConfigState = {
  getStreamCustomEvents: boolean
  becomeSellerUrl: string
}

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  const becomeSellerUrl = ref('typeform');

  const firebaseConfig = {
    apiKey: config.public.firebaseApiKey,
    authDomain: config.public.firebaseAuthDomain,
    projectId: config.public.firebaseProjectId,
    storageBucket: config.public.firebaseStorageBucket,
    messagingSenderId: config.public.firebaseMessagingSenderId,
    appId: config.public.firebaseAppId,
    measurementId: config.public.firebaseMeasurementId,
  };

  try {
    const app = await initializeApp(firebaseConfig);

    const remoteConfig = await getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

    await fetchAndActivate(remoteConfig);

    becomeSellerUrl.value = getValue(remoteConfig, 'become_seller_url').asString();
  } catch (err) {
    console.log('Firebase error:', err);
  }

  return {
    provide: {
      becomeSellerUrl,
    },
  };
});