<script setup lang="ts">
// import { useGtm } from '@gtm-support/vue-gtm';
import { useState } from 'vue-gtag-next';

// const gtm = useGtm();

const { isEnabled } = useState();
const enableGtag = () => {
  isEnabled.value = true;
};

const emit = defineEmits(['answer']);

const showBanner = ref(false);

const accept = () => {
  localStorage.setItem('consent', 'true');

  // google analytics
  enableGtag();
  // gtm.enable();

  // facebook pixel
  window.fbq('consent', 'grant');

  // tiktok pixel
  const ttqScript = document.createElement('script');
  ttqScript.type = 'text/javascript';
  ttqScript.text = `
    !function (w, d, t) {
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
      ttq.load('CBV7FABC77U1UBNUB0CG');
      ttq.page();
    }(window, document, 'ttq');
  `;
  document.head.appendChild(ttqScript);

  // emit event
  emit('answer', true);
};

const reject = () => {
  localStorage.setItem('consent', 'false');
  window.fbq('consent', 'revoke');
  emit('answer', false);
};

onMounted(() => {
  setTimeout(() => {
    showBanner.value = true;
  }, 2000);
});
</script>

<template>
  <transition name="fade" appear mode="out-in">
    <div v-if="showBanner" class="consent cookies">
      <p class="consent__cookies">
        <span>🍪</span>
        <span>🍪</span>
        <span>🍪</span>
        <span>🍪</span>
      </p>
      <p class="consent__text">Cookies? we use them to give best experience when browsing our website. <NuxtLink to="/cookies">Learn More</NuxtLink></p>
      <div class="consent__button-group">
        <TiltButton @click="reject" color="secondary" text="Reject" size="sm" full-width/>
        <TiltButton @click="accept" color="primary" text="Accept" size="sm" full-width/>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.consent {
  @apply bg-neutral-2;
  position: fixed;
  z-index: 99;
  bottom: 40px;
  right: 40px;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
  box-shadow: 0px 0px 28px #080808;
  border-radius: 20px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    width: calc(100% - 32px);
    max-width: none;
    left: 16px;
    bottom: 16px;
    right: 16px;
  }
  &__cookies {
    & span {
      text-shadow: 0px 0px 5px rgba(28, 27, 29, 0.9);
      margin-left: -12px;
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
      position: relative;
    }
    & span:nth-of-type(1) {
      z-index: 4;
      margin-left: 0;
    }
    & span:nth-of-type(2) {
      z-index: 3;
    }
    & span:nth-of-type(3) {
      z-index: 2;
    }
    & span:nth-of-type(4) {
      z-index: 1;
    }
  }
  &__text {
    & a {
      @apply text-neutral-5;
      text-decoration: underline;
      &:hover {
        color: darken(#FAFAFA, 10%);
      }
    }
  }
  &__button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
</style>
